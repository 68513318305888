import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from './temp/config';
import Layout from './Layout';
import componentFactory from './componentFactory';
import { RouteDataManager } from './lib/RouteDataManager';
import { useAppStateContext } from './lib/AppStateProvider';
import { ComponentFactoryContextProvider } from './lib/ComponentFactoryProvider';
// This context is required for @dtcm components to fetch context properly.
import { SitecoreContextReactContext as DtcmUiComponentsSitecoreContext } from '@dtcm/ui-components';
import { get } from './lib/getPropertyByPath';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// This is the main entry point of the app invoked by the renderer (server or client rendering).
const AppRoot = ({ Router }: { Router: any }) => {
  const { appState, actions } = useAppStateContext();

  const isEmptyLayout = get(appState, 'dictionary.viewBag.isEmptyLayout', false);

  // Accessibility settings require DOM manipulation, so initialize them after first mount/render.
  // `useEffect` will only fire once when providing an empty array as the second argument.
  useEffect(() => {
    actions.initializeAccessibilitySettings({
      contrast: appState.selectedContrastValue,
      fontSize: appState.selectedFontSize,
    });
  }, []);

  const sitecoreContextData: any = {
    route: null,
    rtl: false,
    config: config,
    pageEditing: false,
    origin: '',
    breadcrumb: [],
  };

  if (appState.routeData && appState.sitecoreContextData) {
    sitecoreContextData.route = appState.routeData;
    Object.assign(sitecoreContextData, appState.sitecoreContextData);
  }

  return (
    <Router>
      {!isEmptyLayout && <ScrollToTop />}
      <RouteDataManager>
        <ComponentFactoryContextProvider componentFactory={componentFactory}>
          <DtcmUiComponentsSitecoreContext.Provider value={sitecoreContextData}>
            <Layout />
          </DtcmUiComponentsSitecoreContext.Provider>
        </ComponentFactoryContextProvider>
      </RouteDataManager>
    </Router>
  );
};

export default AppRoot;
